import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { SiteFontType } from '@commonTuna/react/objects/SiteFontType';

import { defaultColorSet } from '@app/objects/ColorSet';
import { ApplicationState } from '@app/store';

export const SiteFontTypeNames = {
	[SiteFontType.CormorantGaramondMedium]: 'CormorantGaramondMedium',
	[SiteFontType.CormorantGaramondSemibold]: 'CormorantGaramondSemiBold',
	[SiteFontType.CormorantGaramondBold]: 'CormorantGaramondBold',
	[SiteFontType.ManropeRegular]: 'ManropeRegular',
	[SiteFontType.ManropeSemibold]: 'ManropeSemiBold',
	[SiteFontType.FahkwangRegular]: 'FahkwangRegular',
	[SiteFontType.OpenSansRegular]: 'OpenSansRegular',
	[SiteFontType.PtSansRegular]: 'PtSansRegular',
	[SiteFontType.ElMessiriRegular]: 'ElMessiriRegular',
	[SiteFontType.ElMessiriSemiBold]: 'ElMessiriSemiBold',
};

const SiteScheme: React.FC = ({ children }) => {
	const siteSettings = useSelector((state: ApplicationState) => state.siteViewSettings.item, shallowEqual);
	const colorSet = siteSettings?.colorSet ?? defaultColorSet;

	const style = {
		'--base-color-1': colorSet.baseColor1, // bg without gradient
		'--base-color-2': colorSet.baseColor2, // $light-pink-background
		'--base-color-3': colorSet.baseColor3, // $pink-background
		'--base-color-4': colorSet.baseColor4, // $dusty-pink-color
		'--base-color-5': colorSet.baseColor5, // $bright-pink-color
		'--base-color-6': colorSet.baseColor6, // font color ??
		'--gradient-color-1': colorSet.gradientColor1, // $beige-bg-circle-color
		'--gradient-color-2': colorSet.gradientColor2, // $pink-bg-circle-color
		'--h1-font': `${SiteFontTypeNames[siteSettings.h1FontType]}, sans-serif`,
		'--h2-font': `${SiteFontTypeNames[siteSettings.h2FontType]}, sans-serif`,
		'--h3-font': `${SiteFontTypeNames[siteSettings.h3FontType]}, sans-serif`,
		'--h4-font': `${SiteFontTypeNames[siteSettings.h4FontType]}, sans-serif`,
	};

	return (
		<div className="site-scheme" style={style as React.CSSProperties}>
			{children}
		</div>
	);
};

export default SiteScheme;
