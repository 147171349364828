export enum SiteFontType
{
	CormorantGaramondMedium,
	CormorantGaramondSemibold,
	CormorantGaramondBold,
	ManropeRegular,
	ManropeSemibold,
	FahkwangRegular,
	OpenSansRegular,
	PtSansRegular,
	ElMessiriRegular,
	ElMessiriSemiBold
}
