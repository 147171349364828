import * as React from 'react';

const NotFoundPage: React.FC = () => {
	return <div className="not-found">
		<div className="not-found__content">
			<h3>404</h3>
		</div>
	</div>;
};

export default NotFoundPage;
