import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { ErrorBoundaryWithLocation } from '@common/react/components/UI/ErrorBoundary/ErrorBoundary';
import { NotFoundPageProvider } from '@common/react/components/Core/NotFoundPageProvider/NotFoundPageProvider';
import { LoadingProvider } from '@common/react/components/Core/LoadingProvider/LoadingProvider';
import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';
import { Loading } from '@common/react/components/UI/Loading/Loading';

import NotFoundPage from '@app/components/NotFoundPage';
import SiteScheme from '@app/components/UI/SiteScheme/SiteScheme';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@app/scss/style.scss';

const Layout: React.FC = ({ children }) => {
	return <>
		<div className="app-container">
			<ErrorBoundaryWithLocation>
				<RequestProvider>
					<SiteScheme>
						<NotFoundPageProvider notFoundComponent={<NotFoundPage />}>
							<LoadingProvider loader={<Loading caption="" />}>
								{children ?? <Outlet />}
							</LoadingProvider>
						</NotFoundPageProvider>
					</SiteScheme>
				</RequestProvider>
			</ErrorBoundaryWithLocation>
		</div>
	</>;
};

export default Layout;
