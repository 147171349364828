import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import Header from '@app/components/UI/Header/Header';
import { Location } from '@app/objects/Location';
import Footer from '@app/components/UI/Footer/Footer';
import { ApplicationState } from '@app/store';
import { getClearedLocation } from '@app/utils/getClearedLocation';
import Background from '@app/components/UI/Background/Background';

const PageLayout: React.FC<{ component? }> = ({ children, component: Component }) => {
	const location: Location = useSelector((state: ApplicationState) => state.location.item, shallowEqual);
	const clearedLocation = getClearedLocation(location);

	return (
		<div className="page-layout">
			<div className="relative-container main-background">
				<Background count={5}>
					<Header location={clearedLocation} />
					<div className="container">
						{Component ? <Component /> : children ?? <Outlet />}
					</div>
				</Background>
			</div>
			<Footer location={clearedLocation} />
		</div>
	);
};

export default PageLayout;
