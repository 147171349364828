import * as React from 'react';

import noImage from '@images/no-image.jpg';

const Logo: React.FC<{ src: string }> = ({ src }) => {
	return <div className="logo">
		<img src={src ? `/remote/${src}` : noImage} alt="Northern Tuna Sample Clinic logo" />
	</div>;
};

export default Logo;
