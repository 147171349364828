import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import loadable from '@loadable/component';

import NotFoundRoute from '@common/react/components/Routes/NotFoundRoute';
import { loadableDelay, params } from '@common/react/loadable/loadableSettings';

import Layout from '@app/components/Layout';
import MainLayout from '@app/components/Layouts/MainLayout';
import PageLayout from '@app/components/Layouts/PageLayout';

const Home = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "HomePage" */
		'@app/components/Pages/Home/Home'
	)), params);
const Blog = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Blog" */
		'@app/components/Pages/Blog/Blog'
	)), params);
const Faqs = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Faq" */
		'@app/components/Pages/Faqs/Faqs'
	)), params);

export const baseRoutes = (
	<>
		<Route path="/" element={<MainLayout />}>
			<Route path="/" element={<Home />} />
		</Route>
		<Route path="/" element={<PageLayout />}>
			<Route path="/blog*" element={<Blog />} />
			<Route path="/faq/:page?" element={<Faqs />} />
		</Route>
		<Route
			path="*"
			element={<NotFoundRoute
				openRoute={(props) => <PageLayout {...props} />}
			/>}
		/>
	</>
);

export const clientRoutes = (<Route element={<Layout />}>
	{baseRoutes}
</Route>);

export const routes = (
	<Layout>
		<Routes>
			{baseRoutes}
		</Routes>
	</Layout>
);
