import * as React from 'react';

import BookAppointmentForm from '@app/components/UI/BookAppointmentForm/BookAppointmentForm';
import { ClearedLocation } from '@app/objects/Location';
import Background from '@app/components/UI/Background/Background';
import Logo from '@app/components/UI/Logo/Logo';

const Footer: React.FC<{location: ClearedLocation}> = ({ location }) => {
	const phone = location.phone;

	return <section className="footer" id="footer">
		<div className="footer__info-wrapper flex-container relative-container">
			<Background count={5}>
				<div className="footer__info">
					<Logo src={location.siteAvatar} />
					<div className="footer__contacts">
						<div className="footer__address">
							<div>Address:</div>
							{location.address}
						</div>
						<div className="footer__data">
							<a href={`tel:${phone}`} className="footer__phone nobr">
								{phone}
							</a>
							<div className="footer__schedule">
								<div>{location.workingDays}</div>
								<div>{location.workingHours}</div>
							</div>
						</div>
					</div>
				</div>
			</Background>
		</div>
		<div className="footer__form flex-container">
			<div className="book-apt-form__container">
				<BookAppointmentForm />
			</div>
		</div>
	</section>;
};

export default Footer;
