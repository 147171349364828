import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import Header from '@app/components/UI/Header/Header';
import { Location } from '@app/objects/Location';
import Footer from '@app/components/UI/Footer/Footer';
import { ApplicationState } from '@app/store';
import { getClearedLocation } from '@app/utils/getClearedLocation';

const MainLayout: React.FC = ({ children }) => {
	const location: Location = useSelector((state: ApplicationState) => state.location.item, shallowEqual);
	const clearedLocation = getClearedLocation(location);

	return (
		<div className="main-layout">
			<Header location={clearedLocation} />
			<div className="content">
				{children ?? <Outlet />}
			</div>
			<Footer location={clearedLocation} />
		</div>
	);
};

export default MainLayout;
