export enum DaysOfWeek {
	Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
}

export const DaysOfWeekNames = {
	[DaysOfWeek.Sunday]: 'Sunday',
	[DaysOfWeek.Monday]: 'Monday',
	[DaysOfWeek.Tuesday]: 'Tuesday',
	[DaysOfWeek.Wednesday]: 'Wednesday',
	[DaysOfWeek.Thursday]: 'Thursday',
	[DaysOfWeek.Friday]: 'Friday',
	[DaysOfWeek.Saturday]: 'Saturday',
};
