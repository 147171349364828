import React from 'react';

interface TextWithEllipsisProps {
	text: string | null | undefined;
	count: number;
	className?: string;
}

const TextWithEllipsis: React.FC<TextWithEllipsisProps> = ({ text, count, className = '' }) => {
	return (
		<div className={className}>
			{text && text.length > count
				? `${text?.substring(0, count)}...`
				: text
			}
		</div>
	);
};

export default TextWithEllipsis;
