import * as React from 'react';

import { NavLink } from 'react-router-dom';

import { ClearedLocation } from '@app/objects/Location';
import Logo from '@app/components/UI/Logo/Logo';
import TextWithEllipsis from '@app/components/UI/TextWithEllipsis/TextWithEllipsis';

const Header: React.FC<{location: ClearedLocation}> = ({ location }) => {
	const phone = location.phone;

	return <div className="header">
		<NavLink to="/">
			<Logo src={location.siteAvatar} />
		</NavLink>
		<div className="header-info">
			<div className="header-info__block">
				<div className="header-info__title">Address:</div>
				<TextWithEllipsis text={location.address} count={90} />
			</div>
			<div className="header-info__block">
				<div className="header-info__title">Schedule</div>
				<TextWithEllipsis text={location.workingDays} count={25} />
				<TextWithEllipsis text={location.workingHours} count={20} />
			</div>
			<div className="header-info__block phone nobr">
				<i className="fa fa-phone phone__icon" />
				<a href={`tel:${phone}`} className="phone__number">
					{phone}
				</a>
			</div>
		</div>
	</div>;
};

export default Header;
